import React, { useState, useEffect, ReactNode } from 'react';
import io, { Socket } from 'socket.io-client';
import SocketContext from './SocketContext';
import APPConfig from '../config/config';
import DynamicInfoModal from '../components/modal/DynamicInfoModal';
import { navigateToChat, navigateToLogin } from '../utils/NavigationUtils';


interface SocketProviderComponentProps {
    children: ReactNode;
    isAuthenticated: boolean;
}

const SocketProviderComponent: React.FC<SocketProviderComponentProps> = ({ children, isAuthenticated }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [openInfo, setOpenInfo] = useState<{ isOpen: boolean; infoConfig: any}>({
        isOpen: false,
        infoConfig: {},
    });
    // const navigate = useNavigate();

    const connectSocket = async () => {
        if (!isAuthenticated) return;
        await disconnectSocket();
        const newSocket = io(APPConfig.API_URL);
        setSocket(newSocket); 
    };

    const onloginConnectSocket = async () => {
        await disconnectSocket();
        const newSocket = io(APPConfig.API_URL);
        setSocket(newSocket);
    };

    const connectCustomerSocket = async () => {
        await disconnectSocket();
        const newSocket = io(APPConfig.API_URL);
        setSocket(newSocket);
    };

    const disconnectSocket = () => {
        if (socket) {
            socket.disconnect();
            setSocket(null);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
          connectSocket();
        } else {
            disconnectSocket();
        }
        // Cleanup on unmount or if authentication state changes
        return () => {
            disconnectSocket();
        };
    }, [isAuthenticated]);

    useEffect(()=>{
      const user:any = localStorage.getItem('user');
      let userData = user ? JSON.parse(user): {};
      if(socket && userData && userData.company_id){
        //agent
        if(userData.type === "agent" || userData.type === "admin"){
            socket.on(APPConfig.SOCKET_NEW_CHAT + "_" + userData.company_id, (data) => {
                setOpenInfo({
                    isOpen: true,
                    infoConfig: {
                      afterClose:  () => {
                        navigateToChat()
                      },
                      btnText: 'Go to Chat List',
                      icon: 'info',
                      content: 'A new customer chat has been added to the Queued list. Please review the chat and select it from the queue to assist the customer.',
                    },
                });
            });

            socket.on(APPConfig.SOCKET_CHAT_ERROR + userData.company_id, (data) => {
                if(data.is_deleted){
                    setOpenInfo({
                        isOpen: true,
                        infoConfig: {
                          afterClose:  () => {
                            navigateToLogin()
                          },
                          btnText: 'Okay',
                          icon: 'info',
                          hasClose: false,
                          content: "We're sorry, but the company you’re currently logged into is no longer available. As a result, this account is no longer accessible, and we will have to end your account session.",
                        },
                    });
                }
            });
        }
      }
    }, [socket])

    return (
        <SocketContext.Provider value={{ socket, connectSocket, disconnectSocket, connectCustomerSocket, onloginConnectSocket }}>
            {children}
            <DynamicInfoModal isSound={true} visible={openInfo.isOpen} setVisible={setOpenInfo} {...openInfo.infoConfig} />
        </SocketContext.Provider>
    );
};

export default SocketProviderComponent;

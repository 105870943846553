import { createGlobalStyle } from 'styled-components';
import PoppinsMedium from '../assets/fonts/Poppins/Poppins-Medium.ttf'; // Adjust the path as needed

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Poppins' !important;
    src: url(${PoppinsMedium}) format('truetype');
    font-weight: 500; /* Adjust the font weight as needed */
    font-style: normal; /* Adjust if you have italic/bold variations */
  }

  body {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
  }
`;
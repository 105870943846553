
/**
 * App configuration
 */
class APPConfig {
  /**
   * api url endpoint
   * sample: http://localhost:3000/api
   */
  static API_URL: string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https://gp-api.ark.ph/api';
  // static API_URL:string ='http://localhost:3001/api';
  // static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000/api';
  static DEFAULT_TIMEZONE: string = process.env.REACT_APP_DEFAULT_TIMEZONE ? process.env.REACT_APP_DEFAULT_TIMEZONE : 'America/New_York';
  static SOCKET_URL: string = process.env.REACT_APP_API_SOCKET_URL ? process.env.REACT_APP_API_SOCKET_URL : 'https://gp-notif.ark.ph/';
  static SOCKET_NEW_CHAT: string = process.env.REACT_APP_SOCKET_NEW_CHAT? process.env.REACT_APP_SOCKET_NEW_CHAT : "";
  static SOCKET_JOIN_PCHAT: string = process.env.REACT_APP_SOCKET_JOIN_PCHAT? process.env.REACT_APP_SOCKET_JOIN_PCHAT : "";
  static UPDATE_QUEUE: string = process.env.REACT_APP_SOCKET_UPDATE_QUEUE? process.env.REACT_APP_SOCKET_UPDATE_QUEUE : "";
  static SOCKET_UPDATE_MESSAGE: string = process.env.REACT_APP_SOCKET_UPDATE_CHAT_MESSAGE? process.env.REACT_APP_SOCKET_UPDATE_CHAT_MESSAGE : "";
  static SOCKET_SEND_MESSAGE: string = process.env.REACT_APP_SOCKET_SEND_MESSAGE? process.env.REACT_APP_SOCKET_SEND_MESSAGE : "";
  static UPDATE_LEFT: string = process.env.REACT_APP_SOCKET_UPDATE_LEFT? process.env.REACT_APP_SOCKET_UPDATE_LEFT : "";
  static SOCKET_END_CHAT: string = process.env.REACT_APP_SOCKET_END_CHAT? process.env.REACT_APP_SOCKET_END_CHAT : "";
  static FE_ENDPOINT: string = process.env.REACT_APP_FE_ENDPOINT? process.env.REACT_APP_FE_ENDPOINT: "";
  static BE_ENDPOINT: string = process.env.REACT_APP_BE_ENDPOINT? process.env.REACT_APP_BE_ENDPOINT: "";
  static UPDATE_SOCKET_TRAFFIC: string = process.env.REACT_APP_SOCKET_UPDATE_TRAFFICS? process.env.REACT_APP_SOCKET_UPDATE_TRAFFICS : "";
  static SOCKET_CHAT_ERROR: string = process.env.REACT_APP_SOCKET_CHAT_ERROR? process.env.REACT_APP_SOCKET_CHAT_ERROR : "";
  
}

export default APPConfig;
import { createContext, useContext } from 'react';
import { Socket } from 'socket.io-client';

interface SocketContextProps {
    socket: Socket | null;
    connectSocket: () => void;
    disconnectSocket: () => void;
    connectCustomerSocket: () => void;
    onloginConnectSocket: () => void;
}

const SocketContext = createContext<SocketContextProps>({
    socket: null,
    connectSocket: () => {},
    disconnectSocket: () => {},
    connectCustomerSocket: () => {},
    onloginConnectSocket: () => {}
});

export const useSocket = () => useContext(SocketContext);
export default SocketContext;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledChevronLeftIcon,
  StyledChevronRightIcon
} from "../../../../components/icons/Icons";
import { StyledContainer, StyledHeaderItemContainer } from "./HeaderViewStyle";
import { notification } from "antd";
import AuthenticationService from "../../../../services/AuthenticationService";
import APITokens from "../../../../services/APITokens";
import { CommonHeaderPage } from "../../../../components/common/AtomCommonComponents";

const HeaderView = (props: any) => {
  const navigate = useNavigate(); // Use hook to access navigation
  const [user, setUser]: any = useState();

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      onLogout();
      localStorage.clear();
      navigate("/login"); // Use navigate to redirect
    } else {
      const data: any = localStorage.getItem("user");
      setUser(JSON.parse(data));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const onLogout = async () => {
    const tokens = APITokens.getLocalTokens() || {};
    try {
      await AuthenticationService.logout({accessToken: tokens.access_token!,refreshToken: tokens.refresh_token || "temp"});
      localStorage.clear();
      navigate("/login");
    } catch (error: any) {
      notification.error({
        message: 'Error',
        description: error.message,
        placement:"top"
      });
    }
  };

  const toggleSidebar = () => {
    props.collapseToggle(!props.siderCollapsed);
  };

  return (
    <>
      <StyledContainer>
        <StyledHeaderItemContainer onClick={toggleSidebar}>
          {props.siderCollapsed ? (
            <StyledChevronRightIcon size="1.5em" />
          ) : (
            <StyledChevronLeftIcon size="1.5em" />
          )}
        </StyledHeaderItemContainer>
        <CommonHeaderPage>
          <p>
               {props.pageHeader? props.pageHeader : ""}
            </p>
          </CommonHeaderPage>
      </StyledContainer>
    </>
  );
};

export default HeaderView;

/**
 * delay return data
 * @param data the data
 * @returns {Promise} the result with promise
 */
import { HttpHelper } from "./AxiosHelper";

export default class AuthenticationService {
  /**
   * login
   * @param entity the login entity
   * @returns {Promise}
   */
  static login(data: any) {
    return HttpHelper.post("auth/login", data);
  }

  static logout(data: any) {
    return HttpHelper.post("auth/logout", data);
  }

  static forgot(data: any) {
    return HttpHelper.post("auth/forgot-password", data);
  }

  static async isLogin(){
    return await localStorage.getItem('access_token') ? true : false
  }

  static async resetPassword (token:string, new_password:string, confirm_password:string) {
    return HttpHelper.post("auth/reset-password", {token, new_password, confirm_password});
  }
}

import moment from "moment";

/**
 *
 */
export default class DateUtils {
  /**
   *
   * @param dateString
   * @param format
   */
  static format(dateString: any, format: string = "MM-DD-YYYY") {
    return dateString ? moment(dateString).format(format) : "";
  }


  static currentYear: string = new Date().getFullYear().toString();
  static currentMonth: string = (new Date().getMonth() + 1).toString();
  static currentQuarter: string = Math.ceil(Number(DateUtils.currentMonth) / 3).toString();

  /**
   * @param monthNumber
   */
  static monthNumberToName(monthNumber: number): string {
    return moment()
      .month(monthNumber - 1)
      .format("MMMM");
  }

  /**
   * Formats the date and time as "YYYY-MM-DD, Time AM/PM"
   * @param dateTimeString The date-time string to be formatted
   */
  static formatDateAndTime(dateTimeString: string): string {
    if (!dateTimeString) return "";

    const formattedDate = moment(dateTimeString).format("YYYY-MM-DD");
    const formattedTime = moment(dateTimeString).format("hh:mm A");

    return `${formattedDate}, ${formattedTime}`;
  }

  static formatMessageDate (dateString: string){
    const now = moment();
    const inputDate = moment(dateString);
    // Check if the input date is today
    if (inputDate.isSame(now, 'day')) {
      return inputDate.format('[Today at] h:mm A');
    }
    // Check if the input date was yesterday
    else if (inputDate.isSame(now.subtract(1, 'days'), 'day')) {
        return inputDate.format('[Yesterday at] h:mm A');
    }
    // For any other day, show the full date
    else {
        return inputDate.format('MMMM D, YYYY [at] h:mm A');
    }
    
  }
}

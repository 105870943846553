import axios from "axios";
import APPConfig from "../config/config";
import APITokens from "./APITokens";
import { navigateToAgent, navigateToChat, navigateToCompany, navigateToLogin } from "../utils/NavigationUtils";

const apiInstance = axios.create({
  baseURL: APPConfig.API_URL + "/api/",
});

const refreshToken = async () => {
  try {
    let baseURL = APPConfig.API_URL + "/api/";
    const [refresh_token, access_token] = await Promise.all([
      APITokens.getLocalTokens().refresh_token, 
      APITokens.getLocalTokens().access_token
    ])
    
    console.log({refresh_token, access_token})
    if (refresh_token && access_token) {
      const result = await axios.post(baseURL + "auth/refresh-token", {
        access_token,
        refresh_token,
      });
      await Promise.all([
        localStorage.setItem("access_token", result.data.access_token? result.data.access_token : access_token),
        localStorage.setItem("refresh_token", result.data.refresh_token? result.data.refresh_token: refresh_token),
      ])
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

apiInstance.interceptors.request.use(
  async (config) => {
    const token = APITokens.getLocalTokens().access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("API request creation failed:", error);
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (!error.response) {
      return Promise.reject(
        new Error(
          "No network connection. Make sure that Wi-Fi or Cellular Mobile Data is turned on, then try again."
        )
      );
    }

    if (error.response.status === 401) {
      if(error.response.data && error.response.data.errors && error.response.data.errors.message === "Your account is forbidden from accessing this."){
        const userData:any = localStorage.getItem('user');
        let user = JSON.parse(userData);
        if(user && user.type === 'super-admin'){
          navigateToCompany();
        }else if (user && user.type === 'admin'){
          navigateToAgent();
        }else if (user && user.type === 'agent'){
          navigateToChat();
        }else{
          localStorage.clear();
          navigateToLogin(); 
        }
        //return error
        return Promise.reject(new Error(error.response.data.errors.message));
      }
      const isRefreshed = await refreshToken();
      if (isRefreshed) {
        // Retry the original request
        return apiInstance(error.config);
      } else {
        localStorage.clear();
        navigateToLogin(); 
        return Promise.reject(new Error("Session expired. Please log in again."));
      }
    }

    const errMsg =
      (error.response.data && error.response.data.errors) ||
      "Unknown error occurred.";
    return Promise.reject(errMsg);
  }
);

export const HttpHelper = apiInstance;
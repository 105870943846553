export const theme = {
  colors: {
    primary: "#000000",
    secondary: "#494F55",
    tertiary: "#EDF3ED",
    quaternary: "#68ba84",
    black: "#203A20",
    white: "#FFFFFF",
    dirty_white: "#E8E4C9",
    //others
    warning: "red",
  },
  fontSizes: {
    small: "12px", // Equivalent to 12px
    medium: "16px", // Equivalent to 16px
    large: "20px", // Equivalent to 20px
    xl: "1.5em",
    xxl: "2em",
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
};

import styled from "styled-components";

interface IconsProps {
  size?: string;
  color?: string;
  $hoverColor?: string;
}

//custom styled component for Icon Wrapper

const StyledIconContainer = styled.div<IconsProps>`
  display: inline-block;
  width: ${(props) => props.size || "1em"};
  height: ${(props) => props.size || "1em"};
`;

const StyledSortIconContainer = styled.div<IconsProps>`
  display: inline-block;
  padding-top: 2px;
`;

const StyledSvg = styled.svg<IconsProps>`
  fill: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
  transition: fill 0.3s ease;

  &:hover {
    fill: ${(props) =>
      props.$hoverColor ? props.$hoverColor : props.theme.colors.secondary} !important;
  }
`;

export const StyledCustomerIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <g />
          <g>
            <path d="M16.67,13.13C18.04,14.06,19,15.32,19,17v3h4v-3C23,14.82,19.43,13.53,16.67,13.13z" />
            <path d="M15,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4c-0.47,0-0.91,0.1-1.33,0.24C14.5,5.27,15,6.58,15,8s-0.5,2.73-1.33,3.76 C14.09,11.9,14.53,12,15,12z" />
            <path d="M9,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4S5,5.79,5,8C5,10.21,6.79,12,9,12z M9,6c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2 S7,9.1,7,8C7,6.9,7.9,6,9,6z" />
            <path d="M9,13c-2.67,0-8,1.34-8,4v3h16v-3C17,14.34,11.67,13,9,13z M15,18H3l0-0.99C3.2,16.29,6.3,15,9,15s5.8,1.29,6,2V18z" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledTellerIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
      >
        <path d="M260-630q-24.75 0-42.375-17.625T200-690v-130q0-24.75 17.625-42.375T260-880h440q24.75 0 42.375 17.625T760-820v130q0 24.75-17.625 42.375T700-630H260Zm0-60h440v-130H260v130ZM140-80q-24.75 0-42.375-17.625T80-140v-70h800v70q0 24.75-17.625 42.375T820-80H140ZM80-240l145-324q8-16 22.603-26 14.603-10 31.397-10h402q16.794 0 31.397 10Q727-580 735-564l145 324H80Zm260-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledAgentIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        color={color}
        $hoverColor={$hoverColor}
      >
        <path d="M12,2a8,8,0,0,0-8,8v1.9A2.92,2.92,0,0,0,3,14a2.88,2.88,0,0,0,1.94,2.61C6.24,19.72,8.85,22,12,22h3V20H12c-2.26,0-4.31-1.7-5.34-4.39l-.21-.55L5.86,15A1,1,0,0,1,5,14a1,1,0,0,1,.5-.86l.5-.29V11a1,1,0,0,1,1-1H17a1,1,0,0,1,1,1v5H13.91a1.5,1.5,0,1,0-1.52,2H20a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2V10A8,8,0,0,0,12,2Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};
export const StyledGroupIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 611.998 611.998"
        width="100%"
        height="100%"
        color={color}
        $hoverColor={$hoverColor}
      >
        <g>
          <g>
            <path d="M382.167,150.945c9.702,10.875,16.557,24.306,20.381,39.921c3.629,14.822,4.44,31.308,2.414,49.006
              c-0.751,6.546-1.861,13.96-3.479,21.802c12.532,12.135,28.95,19.839,50.296,19.838c59.22-0.005,80.529-59.3,86.105-108.006
              c6.872-60.004-21.498-105.163-86.105-105.163c-50.698,0-79.079,27.82-85.628,68.798
              C372.076,141.062,377.449,145.655,382.167,150.945z"/>
            <path d="M611.973,422.704c-0.645-18.899-2.861-37.887-6.161-56.495c-3.992-22.539-9.08-55.585-28.759-70.275
              c-11.38-8.491-26.117-11.278-39.143-16.398c-6.343-2.492-12.024-4.967-17.354-7.784c-17.995,19.734-41.459,30.055-68.782,30.057
              c-21.261,0-40.172-6.281-56.001-18.358c-3.644,11.272-8.522,22.623-15.044,32.994c5.728,3.449,11.923,6.204,19.451,9.162
              c3.332,1.31,6.99,2.506,10.864,3.771c10.472,3.422,22.339,7.301,32.994,15.255c25.329,18.907,31.564,54.336,36.117,80.207
              l0.49,2.792c2.355,13.266,4.084,26.299,5.197,38.961c20.215-2.071,40.327-5.61,60.047-9.774
              c15.941-3.365,31.774-7.471,47.109-13.003C605.247,439.397,612.476,437.343,611.973,422.704z"/>
            <path d="M160.216,281.511c21.345,0.002,37.762-7.703,50.295-19.838c-1.618-7.841-2.728-15.256-3.479-21.802
              c-2.026-17.697-1.214-34.184,2.414-49.006c3.823-15.614,10.679-29.046,20.381-39.921c4.718-5.291,10.09-9.884,16.014-13.805
              c-6.549-40.978-34.93-68.798-85.628-68.798c-64.606,0-92.977,45.16-86.106,105.163
              C79.687,222.212,100.996,281.507,160.216,281.511z"/>
            <path d="M167.957,344.634c10.655-7.954,22.524-11.833,32.994-15.255c3.875-1.265,7.531-2.461,10.864-3.771
              c7.528-2.957,13.725-5.711,19.451-9.162c-6.52-10.369-11.4-21.722-15.043-32.994c-15.829,12.077-34.741,18.358-56.001,18.358
              c-27.322-0.001-50.788-10.324-68.782-30.057c-5.329,2.817-11.012,5.291-17.354,7.784c-13.026,5.12-27.763,7.907-39.143,16.398
              c-19.678,14.691-24.767,47.735-28.759,70.275c-3.3,18.607-5.516,37.595-6.161,56.495c-0.502,14.64,6.726,16.693,18.974,21.112
              c15.334,5.531,31.17,9.637,47.109,13.003c19.72,4.165,39.833,7.704,60.047,9.774c1.112-12.662,2.841-25.693,5.197-38.961
              l0.49-2.792C136.394,398.971,142.628,363.541,167.957,344.634z"/>
            <path d="M470.351,429.405l-0.493-2.805c-4.258-24.197-10.091-57.334-32.191-73.832c-9.321-6.957-19.872-10.404-30.078-13.74
              c-4.019-1.313-7.812-2.554-11.427-3.974c-5.269-2.07-10.016-4.097-14.464-6.338c-18.684,24.932-44.58,38.059-75.383,38.062
              c-30.795,0-56.687-13.128-75.371-38.062c-4.449,2.243-9.196,4.269-14.467,6.34c-3.61,1.418-7.406,2.659-11.424,3.972
              c-10.207,3.335-20.761,6.784-30.079,13.74c-22.107,16.5-27.936,49.645-32.193,73.846l-0.493,2.795
              c-3.557,20.086-5.68,39.572-6.308,57.914c-0.737,21.519,12.62,26.316,24.403,30.55l1.269,0.457
              c14.17,5.112,30.021,9.492,48.457,13.388c37.646,7.946,68.197,11.74,96.138,11.938h0.072h0.072
              c27.946-0.199,58.495-3.992,96.135-11.938c18.439-3.894,34.289-8.274,48.453-13.387l1.268-0.456
              c11.786-4.233,25.147-9.029,24.41-30.553C476.03,468.931,473.906,449.447,470.351,429.405z"/>
            <path d="M221.005,243.009c5.577,48.709,26.883,108.009,86.103,108.006s80.529-59.297,86.106-108.006
              c6.871-60.002-21.503-105.16-86.106-105.16C242.515,137.847,214.123,183.002,221.005,243.009z"/>
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};


export const StyledStoreIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <g />
          <g>
            <path d="M21.9,8.89l-1.05-4.37c-0.22-0.9-1-1.52-1.91-1.52H5.05C4.15,3,3.36,3.63,3.15,4.52L2.1,8.89 c-0.24,1.02-0.02,2.06,0.62,2.88C2.8,11.88,2.91,11.96,3,12.06V19c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-6.94 c0.09-0.09,0.2-0.18,0.28-0.28C21.92,10.96,22.15,9.91,21.9,8.89z M18.91,4.99l1.05,4.37c0.1,0.42,0.01,0.84-0.25,1.17 C19.57,10.71,19.27,11,18.77,11c-0.61,0-1.14-0.49-1.21-1.14L16.98,5L18.91,4.99z M13,5h1.96l0.54,4.52 c0.05,0.39-0.07,0.78-0.33,1.07C14.95,10.85,14.63,11,14.22,11C13.55,11,13,10.41,13,9.69V5z M8.49,9.52L9.04,5H11v4.69 C11,10.41,10.45,11,9.71,11c-0.34,0-0.65-0.15-0.89-0.41C8.57,10.3,8.45,9.91,8.49,9.52z M4.04,9.36L5.05,5h1.97L6.44,9.86 C6.36,10.51,5.84,11,5.23,11c-0.49,0-0.8-0.29-0.93-0.47C4.03,10.21,3.94,9.78,4.04,9.36z M5,19v-6.03C5.08,12.98,5.15,13,5.23,13 c0.87,0,1.66-0.36,2.24-0.95c0.6,0.6,1.4,0.95,2.31,0.95c0.87,0,1.65-0.36,2.23-0.93c0.59,0.57,1.39,0.93,2.29,0.93 c0.84,0,1.64-0.35,2.24-0.95c0.58,0.59,1.37,0.95,2.24,0.95c0.08,0,0.15-0.02,0.23-0.03V19H5z" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledAdminManagementIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="100%"
        height="100%"
        color={color}
        $hoverColor={$hoverColor}
      >
        <g>
          <path d="M25.1,41H4a2,2,0,0,1-2-2V31.1l1-.6A25.6,25.6,0,0,1,16,27a26.7,26.7,0,0,1,7.5,1.1,21.2,21.2,0,0,0-.5,4.4A18.4,18.4,0,0,0,25.1,41Z"/>
          <path d="M16,23a9,9,0,1,0-9-9A9,9,0,0,0,16,23Z"/>
          <path d="M46,34.1V31.9L42.4,31l-.5-1.1,2-3.2-1.6-1.6-3.2,2L38,26.6,37.1,23H34.9L34,26.6l-1.1.5-3.2-2-1.6,1.6,2,3.2L29.6,31l-3.6.9v2.2l3.6.9.5,1.1-2,3.2,1.6,1.6,3.2-2,1.1.5.9,3.6h2.2l.9-3.6,1.1-.5,3.2,2,1.6-1.6-2-3.2.5-1.1ZM36,36a3,3,0,1,1,3-3A2.9,2.9,0,0,1,36,36Z"/>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledCompanyManagementIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"  // Changed viewBox to match the original SVG
        fill="currentColor"
      >
        <path d="M8 2L8 6L4 6L4 48L46 48L46 14L30 14L30 6L26 6L26 2 Z M 10 4L24 4L24 8L28 8L28 46L19 46L19 39L15 39L15 46L6 46L6 8L10 8 Z M 10 10L10 12L12 12L12 10 Z M 14 10L14 12L16 12L16 10 Z M 18 10L18 12L20 12L20 10 Z M 22 10L22 12L24 12L24 10 Z M 10 15L10 19L12 19L12 15 Z M 14 15L14 19L16 19L16 15 Z M 18 15L18 19L20 19L20 15 Z M 22 15L22 19L24 19L24 15 Z M 30 16L44 16L44 46L30 46 Z M 32 18L32 20L34 20L34 18 Z M 36 18L36 20L38 20L38 18 Z M 40 18L40 20L42 20L42 18 Z M 10 21L10 25L12 25L12 21 Z M 14 21L14 25L16 25L16 21 Z M 18 21L18 25L20 25L20 21 Z M 22 21L22 25L24 25L24 21 Z M 32 22L32 24L34 24L34 22 Z M 36 22L36 24L38 24L38 22 Z M 40 22L40 24L42 24L42 22 Z M 32 26L32 28L34 28L34 26 Z M 36 26L36 28L38 28L38 26 Z M 40 26L40 28L42 28L42 26 Z M 10 27L10 31L12 31L12 27 Z M 14 27L14 31L16 31L16 27 Z M 18 27L18 31L20 31L20 27 Z M 22 27L22 31L24 31L24 27 Z M 32 30L32 32L34 32L34 30 Z M 36 30L36 32L38 32L38 30 Z M 40 30L40 32L42 32L42 30 Z M 10 33L10 37L12 37L12 33 Z M 14 33L14 37L16 37L16 33 Z M 18 33L18 37L20 37L20 33 Z M 22 33L22 37L24 37L24 33 Z M 32 34L32 36L34 36L34 34 Z M 36 34L36 36L38 36L38 34 Z M 40 34L40 36L42 36L42 34 Z M 32 38L32 40L34 40L34 38 Z M 36 38L36 40L38 40L38 38 Z M 40 38L40 40L42 40L42 38 Z M 10 39L10 44L12 44L12 39 Z M 22 39L22 44L24 44L24 39 Z M 32 42L32 44L34 44L34 42 Z M 36 42L36 44L38 44L38 42 Z M 40 42L40 44L42 44L42 42Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledInfoIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 490.318 490.318"
        fill="currentColor"
      >
        <path d="M245.148,0C109.967,0,0.009,109.98,0.009,245.162c0,135.182,109.958,245.156,245.139,245.156
          c135.186,0,245.162-109.978,245.162-245.156C490.31,109.98,380.333,0,245.148,0z M245.148,438.415
          c-106.555,0-193.234-86.698-193.234-193.253c0-106.555,86.68-193.258,193.234-193.258c106.559,0,193.258,86.703,193.258,193.258
          C438.406,351.717,351.706,438.415,245.148,438.415z"/>
        <path d="M270.036,221.352h-49.771c-8.351,0-15.131,6.78-15.131,15.118v147.566c0,8.352,6.78,15.119,15.131,15.119h49.771
          c8.351,0,15.131-6.77,15.131-15.119V236.471C285.167,228.133,278.387,221.352,270.036,221.352z"/>
        <path d="M245.148,91.168c-24.48,0-44.336,19.855-44.336,44.336c0,24.484,19.855,44.34,44.336,44.34
          c24.485,0,44.342-19.855,44.342-44.34C289.489,111.023,269.634,91.168,245.148,91.168z"/>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledFormIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
        width="100%"
        height="100%"
        color={color}
        $hoverColor={$hoverColor}
      >
        <rect fill="none" height="4.8" rx="1.6" width="27.2" x="12.4" y="26"/>
        <rect fill="none" height="4.8" rx="1.6" width="24" x="12.4" y="35.6"/>
        <g>
          <path d="m36.4 14.8h8.48a1.09 1.09 0 0 0 1.12-1.12 1 1 0 0 0 -.32-.8l-10.56-10.56a1 1 0 0 0 -.8-.32 1.09 1.09 0 0 0 -1.12 1.12v8.48a3.21 3.21 0 0 0 3.2 3.2z"/>
          <path d="m44.4 19.6h-11.2a4.81 4.81 0 0 1 -4.8-4.8v-11.2a1.6 1.6 0 0 0 -1.6-1.6h-16a4.81 4.81 0 0 0 -4.8 4.8v38.4a4.81 4.81 0 0 0 4.8 4.8h30.4a4.81 4.81 0 0 0 4.8-4.8v-24a1.6 1.6 0 0 0 -1.6-1.6zm-32-1.6a1.62 1.62 0 0 1 1.6-1.55h6.55a1.56 1.56 0 0 1 1.57 1.55v1.59a1.63 1.63 0 0 1 -1.59 1.58h-6.53a1.55 1.55 0 0 1 -1.58-1.58zm24 20.77a1.6 1.6 0 0 1 -1.6 1.6h-20.8a1.6 1.6 0 0 1 -1.6-1.6v-1.57a1.6 1.6 0 0 1 1.6-1.6h20.8a1.6 1.6 0 0 1 1.6 1.6zm3.2-9.6a1.6 1.6 0 0 1 -1.6 1.63h-24a1.6 1.6 0 0 1 -1.6-1.6v-1.6a1.6 1.6 0 0 1 1.6-1.6h24a1.6 1.6 0 0 1 1.6 1.6z"/>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledSetUpIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
        color={color}
        $hoverColor={$hoverColor}
      >
        <path d="M46.8,32.4l-3.7-3.1c0.2-1.1,0.3-2.3,0.3-3.4s-0.1-2.3-0.3-3.4l3.7-3.1c1.2-1,1.6-2.8,0.8-4.2L46,12.3c-0.6-1-1.7-1.6-2.9-1.6c-0.4,0-0.8,0.1-1.1,0.2l-4.5,1.7c-1.8-1.6-3.8-2.7-5.8-3.4l-0.8-4.7C30.6,2.9,29.2,2,27.6,2h-3.2c-1.6,0-3,0.9-3.3,2.5l-0.8,4.6c-2.2,0.7-4.1,1.9-5.9,3.4L10,10.8c-0.4-0.1-0.7-0.2-1.1-0.2c-1.2,0-2.3,0.6-2.9,1.6l-1.6,2.8c-0.8,1.4-0.5,3.2,0.8,4.2l3.7,3.1c-0.2,1.1-0.3,2.3-0.3,3.4c0,1.2,0.1,2.3,0.3,3.4l-3.7,3.1c-1.2,1-1.6,2.8-0.8,4.2L6,39.4c0.6,1,1.7,1.6,2.9,1.6c0.4,0,0.8-0.1,1.1-0.2l4.5-1.7c1.8,1.6,3.8,2.7,5.8,3.4l0.8,4.8c0.3,1.6,1.6,2.7,3.3,2.7h3.2c1.6,0,3-1.2,3.3-2.8l0.8-4.8c2.3-0.8,4.3-2,6.1-3.7l4.2,1.7c0.4,0.1,0.8,0.2,1.2,0.2c1.2,0,2.3-0.6,2.9-1.6l1.5-2.6C48.4,35.2,48,33.4,46.8,32.4z M26.1,37.1c-6,0-10.9-4.9-10.9-11s4.8-11,10.9-11s10.9,4.9,10.9,11S32.1,37.1,26.1,37.1z"/>
        <path d="M29,18h-4.6c-0.7,0-1.3,0.4-1.5,1l-2.8,7.2c-0.2,0.5,0.2,1.1,0.8,1.1h4.7l-1.7,6c-0.2,0.6,0.5,0.9,0.9,0.5l7.1-8.3c0.5-0.5,0.1-1.3-0.6-1.3h-3.5l3.1-4.9c0.3-0.5-0.1-1.2-0.7-1.2H29z"/>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const ArchivedIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color} 
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
      >
        <path d="M16 2H0V5H16V2Z" fill={color}/>
        <path d="M1 7H5V9H11V7H15V15H1V7Z" fill={color}/>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const TrafficIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        color={color}
        $hoverColor={$hoverColor}
      >
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M12 0H4V2H1V5L4 8H1V11L4 14V16H12V14L15 11V8H12L15 5V2H12V0ZM8 5C8.82843 5 9.5 4.32843 9.5 3.5C9.5 2.67157 8.82843 2 8 2C7.17157 2 6.5 2.67157 6.5 3.5C6.5 4.32843 7.17157 5 8 5ZM8 14C8.82843 14 9.5 13.3284 9.5 12.5C9.5 11.6716 8.82843 11 8 11C7.17157 11 6.5 11.6716 6.5 12.5C6.5 13.3284 7.17157 14 8 14ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8Z"
        />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledMessageIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="100%"
        height="100%"
        color={color}
        $hoverColor={$hoverColor}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6288 20.4718L13.0867 21.3877C12.6035 22.204 11.3965 22.204 10.9133 21.3877L10.3712 20.4718C9.95073 19.7614 9.74049 19.4063 9.40279 19.2098C9.06509 19.0134 8.63992 19.0061 7.78958 18.9915C6.53422 18.9698 5.74689 18.8929 5.08658 18.6194C3.86144 18.1119 2.88807 17.1386 2.3806 15.9134C2 14.9946 2 13.8297 2 11.5V10.5C2 7.22657 2 5.58985 2.7368 4.38751C3.14908 3.71473 3.71473 3.14908 4.38751 2.7368C5.58985 2 7.22657 2 10.5 2H13.5C16.7734 2 18.4101 2 19.6125 2.7368C20.2853 3.14908 20.8509 3.71473 21.2632 4.38751C22 5.58985 22 7.22657 22 10.5V11.5C22 13.8297 22 14.9946 21.6194 15.9134C21.1119 17.1386 20.1386 18.1119 18.9134 18.6194C18.2531 18.8929 17.4658 18.9698 16.2104 18.9915C15.36 19.0061 14.9349 19.0134 14.5972 19.2098C14.2595 19.4062 14.0492 19.7614 13.6288 20.4718ZM8 11.75C7.58579 11.75 7.25 12.0858 7.25 12.5C7.25 12.9142 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 12.9142 14.25 12.5C14.25 12.0858 13.9142 11.75 13.5 11.75H8ZM7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H16C16.4142 8.25 16.75 8.58579 16.75 9C16.75 9.41421 16.4142 9.75 16 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9Z"
          fill={color}
        />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledAdminProfileIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledGearIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledBurgerIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledHistoryIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledReportIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <g>
            <path d="M15,3H5C3.9,3,3.01,3.9,3.01,5L3,19c0,1.1,0.89,2,1.99,2H19c1.1,0,2-0.9,2-2V9L15,3z M5,19V5h9v5h5v9H5z M9,8 c0,0.55-0.45,1-1,1S7,8.55,7,8s0.45-1,1-1S9,7.45,9,8z M9,12c0,0.55-0.45,1-1,1s-1-0.45-1-1s0.45-1,1-1S9,11.45,9,12z M9,16 c0,0.55-0.45,1-1,1s-1-0.45-1-1s0.45-1,1-1S9,15.45,9,16z" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledVoucherIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="m21.41 11.58-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01 4 11V4h7v-.01l9 9-7 7.02z" />
        <circle cx="6.5" cy="6.5" r="1.5" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledProductIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M480-120q-117 0-198.5-81.5T200-400q0-100 61.5-176.5T420-674q-26-5-50-14.5T327-717q-28-29-37.5-67t-9.5-78v-18q86-2 148 56.5T489-680q14-43 39.5-80t57.5-69q9-9 21-9t21 9q9 9 9 21t-9 21q-25 25-45 54t-34 61q94 24 152.5 99.5T760-400q0 117-81.5 198.5T480-120Zm0-60q92 0 156-64t64-156q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 92 64 156t156 64Zm0-220Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledOptionsIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        version="1.1"
        id="Uploaded to svgrepo.com"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />

        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

        <g id="SVGRepo_iconCarrier">
          <style type="text/css"> </style>
          <g>
            <g>
              <path d="M10,20c-1.657,0-3,1.343-3,3s1.343,3,3,3s3-1.343,3-3S11.657,20,10,20z M10,24 c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,24,10,24z" />
              <circle cx="10" cy="16" r="3" />
              <path d="M10,6C8.343,6,7,7.343,7,9s1.343,3,3,3s3-1.343,3-3S11.657,6,10,6z M10,10 c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,10,10,10z" />
              <rect x="15" y="8" width="10" height="2" />
              <rect x="15" y="15" width="10" height="2" />
              <rect x="15" y="22" width="10" height="2" />
            </g>
            <g>
              <path d="M10,20c-1.657,0-3,1.343-3,3s1.343,3,3,3s3-1.343,3-3S11.657,20,10,20z M10,24 c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,24,10,24z" />
              <circle cx="10" cy="16" r="3" />
              <path d="M10,6C8.343,6,7,7.343,7,9s1.343,3,3,3s3-1.343,3-3S11.657,6,10,6z M10,10 c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,10,10,10z" />
              <rect x="15" y="8" width="10" height="2" />
              <rect x="15" y="15" width="10" height="2" />
              <rect x="15" y="22" width="10" height="2" />
            </g>
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledEditIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 18"
        fill="currentColor"
      >
        <path
          d="M6.40393 14.6569C5.92037 15.2545 5.70769 15.3973 5.03151 15.6658C3.99016 16.0858 2.08203 16.8588 0.840035 17.3611C0.60528 17.4607 -0.281571 17.4109 0.0896222 16.4712C0.510977 15.1635 1.12896 13.2323 1.4821 12.1302C1.73893 11.353 1.84125 11.1516 2.35892 10.6298L7.70611 4.85986L11.4923 8.94538C11.4923 8.94538 7.89271 12.9876 6.40393 14.6569Z"
          fill="#5B5B5B"
        />
        <path
          d="M12.3076 8.06862L8.52344 3.98526L9.33404 3.1084L13.1202 7.19392L12.3076 8.06862Z"
          fill="#5B5B5B"
        />
        <path
          d="M15.5519 4.56725L13.9307 6.31881L10.1445 2.23329L11.7678 0.483897C12.3657 -0.161299 13.3328 -0.161299 13.9307 0.483897L15.5539 2.23329C16.1498 2.87632 16.1498 3.92206 15.5519 4.56725Z"
          fill="#5B5B5B"
        />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledTrashIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          d="M15.4073 6.99951C14.9208 6.99951 14.5964 7.34951 14.5964 7.87452V18.1996C14.5964 18.8996 14.1099 19.2496 13.6233 19.2496H4.21694C3.56822 19.2496 3.24386 18.7246 3.24386 18.1996V7.87452C3.24386 7.34951 2.9195 6.99951 2.43297 6.99951C1.94643 6.99951 1.62207 7.34951 1.62207 7.87452V18.1996C1.62207 19.7746 2.75733 20.9996 4.21694 20.9996H13.4612C14.9208 20.9996 16.056 19.7746 16.056 18.1996V7.87452C16.2182 7.34951 15.8938 6.99951 15.4073 6.99951Z"
          fill="#5B5B5B"
        />
        <path
          d="M7.62765 16.6246V7.87452C7.62765 7.34951 7.30329 6.99951 6.97894 6.99951C6.65458 6.99951 6.00586 7.34951 6.00586 7.87452V16.6246C6.00586 17.1496 6.33022 17.4996 6.81676 17.4996C7.30329 17.4996 7.62765 17.1496 7.62765 16.6246Z"
          fill="#5B5B5B"
        />
        <path
          d="M11.8405 16.6246V7.87452C11.8405 7.34951 11.354 6.99951 10.8675 6.99951C10.3809 6.99951 10.2188 7.34951 10.2188 7.87452V16.6246C10.2188 17.1496 10.5431 17.4996 10.8675 17.4996C11.1918 17.4996 11.8405 17.1496 11.8405 16.6246Z"
          fill="#5B5B5B"
        />
        <path
          d="M17.0288 3.50003H12.9743V2.27502C12.9743 1.05001 12.0013 0 10.866 0H6.97371C5.83845 0 4.86538 1.05001 4.86538 2.27502V3.50003H0.810896C0.324358 3.50003 0 3.85004 0 4.37504C0 4.90005 0.324358 5.25005 0.810896 5.25005H17.0288C17.5154 5.25005 17.8397 4.90005 17.8397 4.37504C17.8397 3.85004 17.5154 3.50003 17.0288 3.50003ZM6.48717 2.27502C6.48717 1.92502 6.64935 1.75002 6.97371 1.75002H10.866C11.1904 1.75002 11.3525 1.92502 11.3525 2.27502V3.50003H6.48717V2.27502Z"
          fill="#5B5B5B"
        />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledViewIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5Zm-.353-58Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-146 0-264-83T40-500q58-134 176-217t264-83q146 0 264 83t176 217q-58 134-176 217t-264 83Zm0-300Zm-.169 240Q601-260 702.5-325.5 804-391 857-500q-53-109-154.331-174.5-101.332-65.5-222.5-65.5Q359-740 257.5-674.5 156-609 102-500q54 109 155.331 174.5 101.332 65.5 222.5 65.5Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledMapPinIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledResetIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M13,3c-4.97,0-9,4.03-9,9c0,0.06,0.01,0.12,0.01,0.19l-1.84-1.84l-1.41,1.41L5,16l4.24-4.24l-1.41-1.41l-1.82,1.82 C6.01,12.11,6,12.06,6,12c0-3.86,3.14-7,7-7s7,3.14,7,7s-3.14,7-7,7c-1.9,0-3.62-0.76-4.88-1.99L6.7,18.42 C8.32,20.01,10.55,21,13,21c4.97,0,9-4.03,9-9S17.97,3,13,3z M15,11v-1c0-1.1-0.9-2-2-2s-2,0.9-2,2v1c-0.55,0-1,0.45-1,1v3 c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-3C16,11.45,15.55,11,15,11z M14,11h-2v-1c0-0.55,0.45-1,1-1s1,0.45,1,1V11z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledCheckIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledWrongIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <path d="M15.73,3H8.27L3,8.27v7.46L8.27,21h7.46L21,15.73V8.27L15.73,3z M19,14.9L14.9,19H9.1L5,14.9V9.1L9.1,5h5.8L19,9.1V14.9z M14.83,7.76L12,10.59L9.17,7.76L7.76,9.17L10.59,12l-2.83,2.83l1.41,1.41L12,13.41l2.83,2.83l1.41-1.41L13.41,12l2.83-2.83 L14.83,7.76z" />
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledChevronLeftIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const CheckIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 7.5L7.47368 13L18 2" stroke="#508553" stroke-width="3" strokeLinecap="round" />
    </svg>
  );
};

export const StyledChevronRightIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledSortArrowsIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledSortIconContainer>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M322-450v-316L202-646l-42-42 193-193 193 193-42 42-122-121v317h-60ZM607-81 414-275l42-41 120 120v-316h60v317l122-121 42 42L607-81Z" />
      </StyledSvg>
    </StyledSortIconContainer>
  );
};

export const StyledCloseIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledDownloadIcon = ({ size, color, $hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        $hoverColor={$hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M480-313 287-506l43-43 120 120v-371h60v371l120-120 43 43-193 193ZM220-160q-24 0-42-18t-18-42v-143h60v143h520v-143h60v143q0 24-18 42t-42 18H220Z" />
        ;
      </StyledSvg>
    </StyledIconContainer>
  );
};

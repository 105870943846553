import { Menu } from "antd";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
`;

export const StyledHeaderItemContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledMenu = styled(Menu)`
  position: relative;
  width: 130px;
  margin-top: 0.5rem;
  padding: 0.5rem;

  .ant-divider {
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0;
  }
`;

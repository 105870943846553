import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import {
  CommonAlignCenterContainer,
} from "../../../../components/common/AtomCommonComponents";
import { UserOutlined } from "@ant-design/icons";
import {
  ArchivedIcon,
  StyledAdminManagementIcon,
  StyledCompanyManagementIcon,
  StyledCustomerIcon,
  StyledFormIcon,
  StyledGroupIcon,
  StyledHistoryIcon,
  StyledMessageIcon,
  StyledProductIcon,
  StyledReportIcon,
  StyledSetUpIcon,
  StyledStoreIcon,
  StyledTellerIcon,
  StyledVoucherIcon,
  TrafficIcon,
} from "../../../../components/icons/Icons";
import {
  ActiveTextMedium,
  HoverClickableTextMedium,
  StyledDivider,
  StyledLogo,
  StyledLogoCollapsed,
  StyledMenu,
  StyledMenuItem,
  StyledProfileAvatarContainer,
  StyledProfileContainer,
  StyledRowContainer,
} from "./SideMenuViewStyles";
import logo from "../../../../assets/images/logo-long.png";
import logoCollapsed from "../../../../assets/images/logo-white.png";
import { Avatar, notification } from "antd";
import TextFormatUtils from "../../../../utils/TextFormatUtils";
import APITokens from "../../../../services/APITokens";
import AuthenticationService from "../../../../services/AuthenticationService";
import { useSocket } from "../../../../context/SocketContext";


const getUser = () => {
  const data: any = localStorage.getItem("user");
  return data;
};

const SideMenuView = ({ siderCollapsed, collapseToggle, setPageHeader }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const {disconnectSocket } = useSocket();

  const data = JSON.parse(getUser());
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const sidebarSuperAdmin = [
    {
      name: "Companies",
      url: "/company-management",
      icon: <StyledCompanyManagementIcon />,
    },
  ];

  const sidebarAdmin = [
    {
      name: "Users",
      url: "/user-management",
      icon: <StyledAdminManagementIcon />,
    },
    {
      name: "Chats",
      url: "/chat-management",
      icon: <StyledMessageIcon/>,
    },
    {
      name: "Traffics",
      url: "/traffic-management",
      icon: <TrafficIcon/>,
    },
    {
      name: "Archives",
      url: "/archive-management",
      icon: <ArchivedIcon/>,
    },
    {
      name: "Pre-chat Form",
      url: "/pre-chat-management",
      icon: <StyledFormIcon />,
    },
    {
      name: "Live Chat Setup",
      url: "/live-chat-management",
      icon: <StyledSetUpIcon/>,
    },
    {
      name: "Groups",
      url: "/group-management",
      icon: <StyledGroupIcon />,
    },
  ];

  const sidebarAgent = [
    {
      name: "Chats",
      url: "/chat-management",
      icon: <StyledMessageIcon/>,
    },
    {
      name: "Archives",
      url: "/archive-management",
      icon: <ArchivedIcon/>,
    },
  ];

  useEffect(() => {
    const keyPath = keyPathTransformer(`/${location.pathname.split("/")[1]}`);
    const pageHeader = getPageHeader(keyPath);
    setPageHeader(pageHeader);
    setCurrentPath(keyPath);
  }, [location.pathname]);

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      disconnectSocket();
      onLogout();
    }
  }, []);

  useEffect(() => {
    if (!data) {
      disconnectSocket();
      onLogout();
    }
  }, []);

  const keyPathTransformer = (value: string) => {
    switch (value) {
      case "/company-management": {
        return "/company-management";
      }
      case "/admin-management": {
        return "/admin-management";
      }
      case "/user-management": {
        return "/user-management";
      }
      case "/chat-management": {
        return "/chat-management";
      }
      case "/pre-chat-management": {
        return "/pre-chat-management";
      }
      case "/archive-management": {
        return "/archive-management";
      }
      case "/live-chat-management": {
        return "/live-chat-management";
      }
      case "/group-management": {
        return "/group-management";
      }
      case "/traffic-management": {
        return "/traffic-management";
      }
      default: {
        return "";
      }
    }
  };

  const getPageHeader = (value: string) => {
    switch (value) {
      case "/company-management": {
        return "Company Management";
      }
      case "/admin-management": {
        return "Admin Management";
      }
      case "/user-management": {
        return "User Management";
      }
      case "/chat-management": {
        return "Chat Management";
      }
      case "/pre-chat-management": {
        return "Pre-chat Form Management";
      }
      case "/archive-management": {
        return "Archive Management";
      }
      case "/live-chat-management": {
        return "Live Chat Setup";
      }
      case "/group-management": {
        return "Group Management";
      }
      case "/traffic-management": {
        return "Traffic Management";
      }
      default: {
        return "";
      }
    }
  };

  const onLogout = async () => {
    disconnectSocket();
    const tokens = APITokens.getLocalTokens() || {};
    try {
      await AuthenticationService.logout({accessToken: tokens.access_token!,refreshToken: tokens.refresh_token || "temp"});
      localStorage.clear();
      navigate("/login");
    } catch (error: any) {
      notification.error({
        message: 'Error',
        description: error.message,
        placement:"top"
      });
    }
  };

  const onToggleMenus = (action: string) => {
    if(!isOnline){
      isOffline();
    }
    if (window.innerWidth <= 768) {
      collapseToggle(!siderCollapsed);
    }
    setCurrentPath(action);
    navigate(action);
  };

  const isOffline = () => {
    
    let content = "No network connection. Make sure that Wi-Fi or Cellular Mobile Data is turned on, then try again."
    // DialogUtils.error(content);
    //error
  }

  const profileInfo = {
    name: data ? data.first_name + " " + data.last_name : null,
    type: data ? data.type.charAt(0).toUpperCase() + data.type.slice(1) : null,
  };

  const getSidebar = () => {
    if(data.type === 'super-admin'){
      return <>
        {sidebarSuperAdmin.map(({ name, url, icon }) => (
          <StyledMenuItem
            key={url}
            onClick={() => onToggleMenus(url)}
            $iscollapsed={siderCollapsed}
            icon={icon}
          >
            <span>{name}</span>
          </StyledMenuItem>
        ))}
      </>
    } else if (data.type === 'admin'){
      return <>
        {sidebarAdmin.map(({ name, url, icon }) => (
          <StyledMenuItem
            key={url}
            onClick={() => onToggleMenus(url)}
            $iscollapsed={siderCollapsed}
            icon={icon}
          >
            <span>{name}</span>
          </StyledMenuItem>
        ))}
      </>
    }else{
      return <>
        {sidebarAgent.map(({ name, url, icon }) => (
          <StyledMenuItem
            key={url}
            onClick={() => onToggleMenus(url)}
            $iscollapsed={siderCollapsed}
            icon={icon}
          >
            <span>{name}</span>
          </StyledMenuItem>
        ))}
      </>
    }
  }

  return (
    <StyledRowContainer $iscollapsed={siderCollapsed}>
      <StyledMenu
        $iscollapsed={siderCollapsed}
        selectedKeys={[currentPath]}
        defaultSelectedKeys={[currentPath]}
      >
        {/* Logo Contianer*/}
        <CommonAlignCenterContainer>
          <StyledLogo $iscollapsed={siderCollapsed} src={logo} alt={"logo"} />
          <StyledLogoCollapsed $iscollapsed={siderCollapsed} src={logoCollapsed} alt={"logo"} />
        </CommonAlignCenterContainer>
        {getSidebar()}
        {!siderCollapsed ? (
          <>
            <StyledProfileContainer>
              <StyledMenuItem
                $iscollapsed={siderCollapsed}
                icon={<LogoutOutlined />}
                onClick={() => onLogout()}
                $isLogoutCollapsed={true}
              >
                <span>Logout</span>
              </StyledMenuItem>
              <StyledDivider />

              <StyledProfileAvatarContainer>
                <div>
                  <Avatar size={45} icon={<UserOutlined />} />
                </div>
                <div>
                  {window.location.pathname === "/admin-profile" ? (
                    <ActiveTextMedium $isShrink={true} color="white">
                      {TextFormatUtils.ellipsis(profileInfo.name ? profileInfo.name : " ", 30)}
                    </ActiveTextMedium>
                  ) : (
                    <HoverClickableTextMedium
                      // onClick={() => {
                      //   navigate("/admin-profile");
                      // }}
                      $isShrink={true}
                      color="white"
                    >
                      {TextFormatUtils.ellipsis(profileInfo.name ? profileInfo.name : " ", 30)}
                    </HoverClickableTextMedium>
                  )}
                </div>
              </StyledProfileAvatarContainer>
            </StyledProfileContainer>
          </>
        ) : (
          <StyledProfileContainer $isLogoutCollapsed={true}>
            <StyledMenuItem key={1} onClick={() => onLogout()} icon={<LogoutOutlined />} />
          </StyledProfileContainer>
        )}
      </StyledMenu>
    </StyledRowContainer>
  );
};

export default SideMenuView;

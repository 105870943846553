import { Checkbox, Dropdown, Form, Menu, Modal, Table } from "antd";
import styled from "styled-components";

interface ModalProps {
  isTitleCenter?: boolean;
  marginTop?: string;
}

interface TableProps {
  isInModal?: boolean;
  scrollX?: boolean;
}

export const CommonAuthForm = styled(Form)`
  width: 420px;
  padding: 30px 60px !important;
  border-radius: 5px;
`;

export const CommonFormItemWithLabel = styled(Form.Item)`
  display: block !important;
  
  .ant-row{
    display: block !important;
  }
  .ant-col{
    text-align: start !important;
  }

  label {
    text-align: start !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .ant-form-item-explain-error {
    padding-left: 20px;
  }
`;

export const CommonActiveFormItemWithLabel = styled(Form.Item)`
  width: 100px;
  margin-bottom: 0px !important;
  .ant-col{
    text-align: start !important;
  }

  label {
    text-align: start !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-form-item-control-input-content{
    max-width: 45px;
  }

  .ant-form-item-explain-error {
    padding-left: 20px;
  }
`;

export const CommonModal = styled(Modal)<ModalProps>`
  border-radius: 10px;
  margin-top: ${(props) => props.marginTop};
  @media (max-height: 730px) {
    margin-top: -70px;
  }
  .ant-modal-title {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-btn-default {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 5px;
  }

  .ant-btn-primary {
    background-color: ${(props) => props.theme.colors.primary} !important;
    border-radius: 5px;
  }
  .ant-modal-header{
    border-bottom: 1px solid #f0f0f0;
    padding: 16px 0px;
  }
  .ant-modal-title {
    display: ${(props) => props.isTitleCenter && "flex"};
    justify-content: ${(props) => props.isTitleCenter && "center"};
  }
`;

export const CommonStyledTable = styled(Table)<TableProps>`
  border-radius: 10px !important;
  width: 100%;
  overflow: hidden;
  overflow-x: ${(props) => props.scrollX && "scroll"};
  margin-bottom: ${(props) => props.isInModal && "15px"};

  .ant-table-thead th {
    background-color: ${(props) => props.theme.colors.tertiary} !important;
    text-align: center !important;
    vertical-align: middle;
  }
  
  .ant-table-tbody > tr > td {
    padding: 6px 16px !important;
  }

  .stick_column {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .fixed_column {
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1);
    font-size: ${(props) => props.theme.fontSizes.medium};
    padding: 0px 50px 0px 50px;
  }

  .amount_right {
    text-align: right;
    white-space: nowrap;
  }

  .discount_amount{
    width: 180px;
  }

  .fixed_column {
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1);
    font-size: ${(props) => props.theme.fontSizes.medium};
  }

  .text_center {
    text-align: center;
    white-space: nowrap;
  }


  li {
    border-radius: 100%;
    background-color: ${(props) => props.theme.colors.tertiary} !important;
    border: none !important;
  }

  a {
    color: ${(props) => props.theme.colors.primary} !important;
    font-size: ${(props) => props.theme.fontSizes.small} !important;
  }
  button {
    border: none !important;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${(props) => props.theme.colors.secondary};
  }

  div.ant-select-selector {
    border: 1px solid ${(props) => props.theme.colors.secondary} !important;
    color: ${(props) => props.theme.colors.primary} !important;
    font-size: ${(props) => props.theme.fontSizes.small} !important;
    border-radius: 10px !important;
  }

  .ant-pagination-item-active{
    border: 1px solid ${(props) => props.theme.colors.primary} !important;
    font-weight: 700;
  }
  
  th:before {
    background-color: #345e37 !important;
  }

`;

export const CommonDropDownActions = styled(Dropdown)`
  display:flex;
  align-items:center: 
  justify-content: center;
`;

export const CommonStyledMenuForDropDown = styled(Menu)`
  position: relative;
  width: 130px;
  margin-top: 0.5rem;
  padding: 0.5rem;

  .ant-divider {
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0;
  }
`;

export const CommonCheckBoxGroup = styled(Checkbox.Group)`
  padding-top: 0.5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  span {
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

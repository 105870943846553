import React from 'react';
import { Navigate } from 'react-router-dom';

interface PublicRouteProps {
  element: React.ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const token = localStorage.getItem('access_token');
  const user:any = localStorage.getItem('user');
  if(user){
    let userData = JSON.parse(user);
    if(userData.type && userData.type === "super-admin"){
      return !token ? element : <Navigate to="/company-management" />;
    }else if(userData.type && userData.type === "admin"){
      return !token ? element : <Navigate to="/user-management" />;
    }else{
      return !token ? element : <Navigate to="/chat-management" />;
    }
  }else{
    return element
  }
  
};

export default PublicRoute;
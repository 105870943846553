let navigateCallback: ((path: string) => void) | null = null;

export const setNavigateCallback = (callback: (path: string) => void) => {
  navigateCallback = callback;
};

export const navigateToLogin = () => {
  if (navigateCallback) {
    localStorage.clear();
    navigateCallback("/login");
  }
};

export const navigateToCompany = () => {
    if (navigateCallback) {
      navigateCallback("/company-management");
    }
};

export const navigateToAgent = () => {
    if (navigateCallback) {
      navigateCallback("/user-management");
    }
};

export const navigateToChat = () => {
    if (navigateCallback) {
      navigateCallback("/chat-management");
    }
};

function disconnectSocket() {
  throw new Error("Function not implemented.");
}


import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme/theme";
import lazyComponentLoader from "./hoc/LazyLoader";
import LayoutView from "./views/layout/LayoutView";
import PublicRoute from "./hoc/PublicRoute";
import ProtectedRoute from "./hoc/ProtectedRoute";
import { GlobalStyle } from "./theme/globalStyle";
import { setNavigateCallback } from "./utils/NavigationUtils";
import SocketProviderComponent from "./context/SocketProvider";

// AUTH
const Login = lazyComponentLoader(() => import("./views/login/LoginView"));
const ForgotPasswordView = lazyComponentLoader(() => import("./views/forgot-password/ForgotPasswordView"));
const ResetPasswordView = lazyComponentLoader(() => import("./views/reset-password/ResetPasswordView"));

// Management
const CompanyManagementView = lazyComponentLoader(() => import("./views/company-management/list/CompanyManagementView"));
const UserManagementView = lazyComponentLoader(() => import("./views/user-management/list/UserManagementView"));
const ChatManagementView = lazyComponentLoader(() => import("./views/chat-management/list/ChatManagementView"));
const PreChatManagementView = lazyComponentLoader(() => import("./views/pre-chat-management/PreChatView"));
const ArchivedManagementView = lazyComponentLoader(() => import("./views/archived-management/list/ArchivedManagementView"));
const LiveChatView = lazyComponentLoader(() => import("./views/live-chat-management/LiveChatView"));
const GroupManagementView = lazyComponentLoader(() => import("./views/group-management/list/GroupManagementView"));
const TrafficManagementView = lazyComponentLoader(() => import("./views/traffic-management/list/TrafficManagementView"));

// Customer views
const CustomerChatView = lazyComponentLoader(() => import("./views/customer-side-chat/CustomerChatView"));

// RedirectChecker component
const RedirectChecker: React.FC = () => {
  const token = localStorage.getItem('access_token');
  const user = localStorage.getItem('user');

  if (user) {
    const userData = JSON.parse(user);
    if (userData.type === "super-admin") {
      return token ? <Navigate to="/company-management" /> : <Navigate to="/login" />;
    } else if (userData.type === "admin") {
      return token ? <Navigate to="/user-management" /> : <Navigate to="/login" />;
    } else {
      return token ? <Navigate to="/chat-management" /> : <Navigate to="/login" />;
    }
  } else {
    return token ? <Navigate to="/admin-management" /> : <Navigate to="/login" />;
  }
};

// Functional component to set navigation callback
const NavigationCallbackSetter: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setNavigateCallback(navigate);
  }, [navigate]);

  return null;
};

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    setIsAuthenticated(!!token);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SocketProviderComponent isAuthenticated={isAuthenticated}>
        <Router>
          <NavigationCallbackSetter /> {/* Set the navigation callback */}
          <Routes>
            <Route path="/" element={<RedirectChecker />} />
            <Route path="/login" element={<PublicRoute element={<Login />} />} />
            <Route path="/forgot-password" element={<PublicRoute element={<ForgotPasswordView />} />} />
            <Route path="/reset-password" element={<PublicRoute element={<ResetPasswordView />} />} />
            <Route path="/company-management" element={<LayoutView><ProtectedRoute element={<CompanyManagementView />} /></LayoutView>} />
            <Route path="/chat-management" element={<LayoutView><ProtectedRoute element={<ChatManagementView />} /></LayoutView>} />
            <Route path="/pre-chat-management" element={<LayoutView><ProtectedRoute element={<PreChatManagementView />} /></LayoutView>} />
            <Route path="/chat/:id" element={<CustomerChatView />} />
            <Route path="/archive-management" element={<LayoutView><ProtectedRoute element={<ArchivedManagementView />} /></LayoutView>} />
            <Route path='/live-chat-management' element={<LayoutView><ProtectedRoute element={<LiveChatView />} /></LayoutView>} />
            <Route path="/group-management" element={<LayoutView><ProtectedRoute element={<GroupManagementView />} /></LayoutView>} />
            <Route path="/user-management" element={<LayoutView><ProtectedRoute element={<UserManagementView />} /></LayoutView>} />
            <Route path="/traffic-management" element={<LayoutView><ProtectedRoute element={<TrafficManagementView />} /></LayoutView>} />
          </Routes>
        </Router>
      </SocketProviderComponent>
    </ThemeProvider>
  );
};

export default App;

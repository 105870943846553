import { Progress } from "antd";
import styled from "styled-components";

interface StyledIconContainerProps {
  isAuth?: boolean;
  width?: string;
}

interface IconContainerProps {
  $isFail: boolean;
}

interface LegendColorProps {
  color: string;
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isAuth ? props.theme.fontSizes.medium : props.width)} !important;

  &:hover svg {
    fill: ${(props) => props.theme.colors.secondary};
  }

  svg {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const StyledPageContentRight = styled.div`
  display: flex;
  flex: 3;
  background-color: ${(props) => props.theme.colors.white};
  justify-content: space-between;
  height: 80px;
  align-items: center;
`;

export const StyledPageContentLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  height: 80px;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.medium};
`;

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background-color: ${(props) => props.theme.colors.secondary};
    width: 30px;
    height: 30px;
    margin: 10px;
  }
`;

export const ModalIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -90px;

  div {
    padding: 10px;
    border-radius: 50px;
    background-color: #ffffff;
  }
`;

export const ModalContentStyle = styled.div`
  margin-top: 14px;
  margin-bottom: 10px;
  text-align: center;
`;

export const ModalTitleStyle = styled.div`
  margin-top: 2px;
  margin-bottom: 10px;
  font-size: 31px;
  text-align: center;
}
`;

export const StyledDynamicModalIconContainerMain = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -46px;
  margin-bottom: 15px;
  background: $transparent;
`;

export const StyledDynamicModalIconContainerInner = styled.div<IconContainerProps>`
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.$isFail ? props.theme.colors.warning : props.theme.colors.primary};
  padding: 8px;
  border-radius: 100%;
`;

export const StyledDynamicModalIconContainerOuter = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  padding: 5px;
  border-radius: 100%;
`;

export const PieChartLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 185px;
  height: 350px;
  margin-top: 3%;
  overflow-y: auto;
  align-items: center;
`;

export const LegendColor = styled.div<LegendColorProps>`
  background-color: ${(props) => props.color};
  margin-right: 5px;
  border-radius: 50px;
  width: 15px;
  height: 15px;
`;

import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { StyledCheckIcon, StyledInfoIcon, StyledResetIcon, StyledWrongIcon } from "../icons/Icons";
import { CommonButton, CommonTextMedium } from "../common/AtomCommonComponents";
import { CommonModal } from "../common/MoleculesCommonComponent";
import { StyledDynamicModalIconContainerInner, StyledDynamicModalIconContainerMain, StyledDynamicModalIconContainerOuter } from "./ModalComponentsStyle";
import Howler from 'react-howler';
import { navigateToChat } from "../../utils/NavigationUtils";

const video = require("../../assets/effects/notif.wav");

const imgStyle = { width: "100%" };

const DynamicInfoModal = ({
  title,
  content,
  info,
  icon,
  visible,
  btnText,
  afterClose,
  setVisible,
  confirmMode = false,
  confirmAction = null,
  isSound = false,
  hasBtn = true,
  hasClose = true,
}: any) => {
  const [open, setOpen] = useState(false);
  const [headIcon, setHeadIcon]: any = useState(null);
  const [isFail, setIsFail] = useState<boolean>(false);
  const [playSound, setPlaySound] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      setOpen(true);
      if (isSound && !playSound) {
        setPlaySound(true);
      }
    } else {
      setOpen(false);
      if (playSound) {
        setPlaySound(false);
      }
    }

    const initialize = () => {
      switch (icon) {
        case "success":
          setHeadIcon(<StyledCheckIcon size="2.5em" color="white" $hoverColor="white" />);
          break;
        case "failed":
          setHeadIcon(<StyledWrongIcon size="2.5em" color="white" $hoverColor="white" />);
          setIsFail(true);
          break;
        case "warning":
          setHeadIcon(<img src={""} alt="reset-success" style={imgStyle} />);
          break;
        case "reset-email":
          setHeadIcon(<StyledResetIcon size="2.5em" color="white" $hoverColor="white" />);
          break;
        case "reset-success":
          setHeadIcon(<img src={""} alt="reset-success" style={imgStyle} />);
          break;
        case "info":
          setHeadIcon(<StyledInfoIcon size="2.5em" color="white" $hoverColor="white"/>);
          break;
        default:
          setHeadIcon(null);
          break;
      }
    };

    initialize();
    
    return () => {
      // Clean up when the component is unmounted or visibility changes
      if (playSound) {
        setPlaySound(false);
      }
    };
  }, [visible, icon, isSound, playSound]);

  return (
    <CommonModal
      open={open}
      onCancel={() => setVisible(false)}
      footer={null}
      closable={hasClose}
      destroyOnClose={true}
      afterClose={() => {
        
      }}
    >
      {isSound && (
        <Howler
          src={video}
          playing={playSound}
          onEnd={() => setPlaySound(false)}
        />
      )}
      <StyledDynamicModalIconContainerMain>
        <StyledDynamicModalIconContainerOuter>
          <StyledDynamicModalIconContainerInner $isFail={isFail}>
            {headIcon ? headIcon : null}
          </StyledDynamicModalIconContainerInner>
        </StyledDynamicModalIconContainerOuter>
      </StyledDynamicModalIconContainerMain>
      {title ? <CommonTextMedium>{title}</CommonTextMedium> : null}
      {info ? <CommonTextMedium>{info}</CommonTextMedium> : null}
      <CommonTextMedium>{content}</CommonTextMedium>
      {hasBtn? (
        <>
          {!confirmMode ? (
            <CommonButton
              background="primary"
              color="white"
              htmlType="button"
              width="100%"
              onClick={() => {
                setVisible(false);
                setOpen(false);
                if (afterClose){
                  afterClose()
                }else{
                  navigateToChat()
                } 
              }}
            >
              {btnText ? btnText : "Ok"}
            </CommonButton>
          ) : (
            <Space>
              <CommonButton htmlType="button" onClick={() => setVisible(false)}>
                CANCEL
              </CommonButton>
              <CommonButton
                htmlType="button"
                onClick={() => {
                  confirmAction();
                  setVisible(false);
                }}
              >
                {btnText ? btnText : "Ok"}
              </CommonButton>
            </Space>
          )}
        </>
      ):null}
    </CommonModal>
  );
};

export default DynamicInfoModal;
